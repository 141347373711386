<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('common.monthly') }}</span> :</div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" :startDefault="startDefault" :dateProps="dateProps" />
         </div>
         <button class="btn-search mr-5" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
         <a class="btn-table" @click="onChangeType('table')">{{ $t('button.tableType') }}</a>
         <a class="btn-table" @click="onChangeType('graph')">{{ $t('button.graphType') }}</a>
         <p class="reportcomment">{{ $t('txt.reportData') }}</p>
      </div>
    </div>

    <div class="main-contents-wrapper">
      <div v-if="type == 'table'"><!-- 테이블 형-->
        <h2 class="tableName">{{ $t('common.table') }}</h2>
        <table class="mainTable">
          <thead>
            <tr>
              <th>형식</th>
              <th>게임사</th>
              <th>베팅</th>
              <th>승</th>
              <th>무</th>
              <th>취소</th>
              <th>유효베팅</th>
              <th>Win/Lose</th>
              <th>환수율</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan='10'>카지노</td>
              <td>에볼류션 카지노</td>
              <td>3,211,278,069</td>
              <td>3,031,414,543</td>
              <td>186,784,400</td>
              <td>0</td>
              <td>3,024,493,669</td>
              <td>-6,920,874</td>
              <td>100.23%</td>
            </tr>
            <tr>
              <td>프라그마틱 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>드림게이밍 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>아시안게이밍 카지노</td>
              <td>28,877,552</td>
              <td>27,142,776</td>
              <td>2,250,000</td>
              <td>362,000</td>
              <td>26,265,552</td>
              <td>-877,224</td>
              <td>103.34%</td>
            </tr>
            <tr>
              <td>CQ9 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>이주기 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>비보게이밍</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>WM카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>오리엔탈게이밍 카지노</td>
              <td>11,948,940</td>
              <td>10,680,500</td>
              <td>0</td>
              <td>0</td>
              <td>11,948,940</td>
              <td>1,268,440</td>
              <td>89.38%</td>
            </tr>
            <tr>
              <td>빅게이밍 카지노</td>
              <td>62,000</td>
              <td>59,100</td>
              <td>4,000</td>
              <td>0</td>
              <td>58,000</td>
              <td>-1,100</td>
              <td>101.9%</td>
            </tr>
            <tr class="sum"> <!-- 카지노 합계 -->
              <td colspan="2">{{ $t('table.head.summary') }}</td>
              <td>3,252,166,561</td>
              <td>3,069,296,920</td>
              <td>189,038,400</td>
              <td>362,000</td>
              <td>3,062,766,161</td>
              <td>-6,530,759</td>
              <td>100.21%</td>
            </tr>
            <tr>
              <td rowspan='2'>호텔카지노</td>
              <td>보타 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>두윈 카지노</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr class="sum"> <!-- 호텔카지노 합계 -->
              <td colspan="2">{{ $t('table.head.summary') }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td rowspan="10">슬롯</td>
              <td>프라그마틱 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>넷엔트 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>레드타이거 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>BTG 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>아시안게이밍 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>CQ9 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>마이크로게이밍 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>UPG 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>하바네로 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>GMW 슬롯</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr class="sum"> <!-- 슬롯 합계 -->
              <td colspan="2">{{ $t('table.head.summary') }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr class="sumEnd"> <!-- 총 합계 -->
              <td colspan="2">{{ $t('table.head.summary') }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
          </tbody>
        </table>
      </div><!-- 테이블 형-->

      <div v-if="type === 'graph'">
        <h2 class="tableName">카지노</h2>
        <div class="chart">
          <apexchart width="400" height="350" type="line" :options="chartOptions" :series="series"></apexchart>
          <apexchart width="400" height="350" type="bar" :options="barChartOptions" :series="barSeries"></apexchart>
          <apexchart width="500" height="350" type="pie" :options="pieChartOptions" :series="pieSeries"></apexchart>
        </div>

        <h2 class="tableName">호텔카지노</h2>
        <div class="chart">
          <apexchart width="400" height="350" type="line" :options="chartOptionsHC" :series="seriesHC"></apexchart>
          <apexchart width="400" height="350" type="bar" :options="barChartOptionsHC" :series="barSeriesHC"></apexchart>
          <apexchart width="500" height="350" type="pie" :options="pieChartOptionsHC" :series="pieSeriesHC"></apexchart>
        </div>

        <h2 class="tableName">슬롯</h2>
        <div class="chart">
          <apexchart width="400" height="350" type="line" :options="chartOptions" :series="series"></apexchart>
          <apexchart width="400" height="350" type="bar" :options="barChartOptions" :series="barSeries"></apexchart>
          <apexchart width="500" height="350" type="pie" :options="pieChartOptions" :series="pieSeries"></apexchart>
        </div>
      </div>
    </div>

  </section>
</template>

<script>

import DateSelector from '@/components/common/DateSelector_monthly'

export default {
  name: 'monthlySettlement',
  components: {
    DateSelector
  },
  data () {
    return {
      type: 'table',
      series: [{
        data: [670, 430, 350, 112, 187]
      }],
      barSeries: [{
        data: [670, 430, 350, 112, 187]
      }],
      pieSeries: [670, 430, 350, 112, 187],
      seriesHC: [{
        data: [670, 430]
      }],
      barSeriesHC: [{
        data: [670, 430]
      }],
      pieSeriesHC: [670, 430],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#F24C3D'],
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        }
      },
      barChartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        colors: ['#59A9D5'],
        labels: ['게임사A', '게임사B', '게임사C', '게임사D', '게임사E'],
        dataLabels: {
          style: {
            colors: ['#353535'],
            fontSize: '12px'
          },
          dropShadow: {
            enabled: false
          }
        }
      },
      pieChartOptions: {
        chart: {
          height: 350,
          type: 'pie'
        },
        colors: ['#8DDFCB', '#82A0D8', '#EDB7ED', '#A8DF8E', '#FFBFBF'],
        labels: ['게임사A', '게임사B', '게임사C', '게임사D', '게임사E'],
        dataLabels: {
          style: {
            colors: ['#353535'],
            fontSize: '16px'
          },
          dropShadow: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      chartOptionsHC: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#F24C3D'],
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        }
      },
      barChartOptionsHC: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        colors: ['#59A9D5'],
        labels: ['보타 카지노', '두윈 카지노'],
        dataLabels: {
          style: {
            colors: ['#353535'],
            fontSize: '12px'
          },
          dropShadow: {
            enabled: false
          }
        }
      },
      pieChartOptionsHC: {
        chart: {
          height: 350,
          type: 'pie'
        },
        colors: ['#8DDFCB', '#82A0D8'],
        labels: ['보타 카지노', '두윈 카지노'],
        dataLabels: {
          style: {
            colors: ['#353535'],
            fontSize: '16px'
          },
          dropShadow: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  methods: {
    onChangeType (type) {
      this.type = type
    }
  }
}
</script>

<style scoped>
.mb20 {margin-bottom: 20px;}
.tableName {margin: 10px 0;}
.btn-table {height: 26px;background: #353535;color: #fff;margin-left: 15px;display: flex;align-items: center;padding: 0 10px;cursor: pointer;font-size: 14px;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable{
  table-layout: fixed;
}
.mainTable td {
  position: relative;
}
.week_day {
  position: absolute;
  left: 4px;
  top: 4px;
  font-weight: 900;
}
.week_no {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 4px;
}
.sum td{background: #ffecec;}
.sumEnd td {background: #ffc2c2;border-top: 3px solid #000;}
.chart {display: flex;gap: 30px;margin-bottom: 15px;flex-wrap: wrap;}
</style>
